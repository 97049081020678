import {
  formatUnit,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import axios from 'axios';
import { useCallback } from 'react';
import { ADD_DEFAULT } from '@/configs/address';
import { API_HOST_BIHOLDER } from '@/configs/apiHost';
import { AXIOS_CONFIG } from '@/configs/axios';
import { formatNumber } from '@/libs/formatNumber';
import Handler from '@/types/Hanlder';
import LendingStatus from '@/types/LendingStatus';
import ResponseLendingStatus from '@/types/response/ResponseLendingStatus';
import useEnv from '../useEnv';

const ENTRYPOINT_LENDING = {
  HOST: API_HOST_BIHOLDER,
  PATH: `/chains/{chainApiIdBifrost}/{apiType}/call-proxy/user`,
  QUERY: '?user_address={address}',
};

const useLendingStatuses = (
  type: 'bifi' | 'btcfi',
  {
    handlers,
  }: {
    handlers: Handler[];
  },
  {
    setStatuses,
    setIsLoading,
  }: {
    setStatuses: (value: LendingStatus[]) => void;
    setIsLoading: (value: boolean) => void;
  }
) => {
  // env
  const { chainBifrost } = useEnv();

  // wallet
  const { account } = useWallet();

  // callback
  const sync = useCallback(async () => {
    if (!(chainBifrost && handlers.length)) {
      return;
    }

    setIsLoading(true);

    try {
      const {
        data: { user_handler_asset: statuses },
      } = await axios.get<ResponseLendingStatus>(
        `${ENTRYPOINT_LENDING.HOST}${ENTRYPOINT_LENDING.PATH.replace(
          '{chainApiIdBifrost}',
          chainBifrost.apiId ?? ''
        ).replace(
          '{apiType}',
          type
        )}${ENTRYPOINT_LENDING.QUERY.replace('{address}', account ?? ADD_DEFAULT)}`,
        AXIOS_CONFIG
      );

      let newAssetStatuses: LendingStatus[] = [];

      if (statuses) {
        newAssetStatuses = statuses
          .map((status) => {
            const {
              handler_id,
              handler_addr,
              token_price,
              limit_of_action,
              deposit_amount,
              deposit_interest_amount,
              deposit_interest_rate,
              borrow_amount,
              borrow_limit,
              borrow_interest_amount,
              borrow_interest_rate,
              user_max_withdraw_amount,
              user_max_borrow_amount,
              user_max_repay_amount,
            } = status;

            return {
              symbol:
                handlers.find((handler) => handler.id === handler_id)?.symbol ||
                '',
              handlerId: handler_id,
              handlerAddress: handler_addr,
              price: formatNumber(formatUnit(token_price, 18)),
              limitOfAction: formatNumber(formatUnit(limit_of_action, 18)),
              deposit: {
                amount: formatNumber(formatUnit(deposit_amount, 18)),
                interest: {
                  amount: formatNumber(formatUnit(deposit_interest_amount, 18)),
                  rate: formatNumber(
                    formatUnit(deposit_interest_rate, 18).multipliedBy(100)
                  ),
                },
              },
              borrow: {
                amount: formatNumber(formatUnit(borrow_amount, 18)),
                limit: formatNumber(
                  formatUnit(borrow_limit, 18).multipliedBy(100)
                ),
                interest: {
                  amount: formatNumber(formatUnit(borrow_interest_amount, 18)),
                  rate: formatNumber(
                    formatUnit(borrow_interest_rate, 18).multipliedBy(100)
                  ),
                },
              },
              maxAmount: {
                withdraw: formatNumber(
                  formatUnit(user_max_withdraw_amount, 18)
                ),
                borrow: formatNumber(formatUnit(user_max_borrow_amount, 18)),
                repay: formatNumber(formatUnit(user_max_repay_amount, 18)),
              },
            };
          })
          .filter((status) => status.symbol);

        setStatuses(newAssetStatuses);
      }
    } catch (error) {}

    setIsLoading(false);
  }, [account, chainBifrost, handlers, setIsLoading, setStatuses, type]);

  return {
    sync,
  };
};

export default useLendingStatuses;
