import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { SWR_CONFIG } from '@/configs/swr';
import useEnv from '@/hooks/useEnv';
import Handler from '@/types/Hanlder';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useAvailableHandlers = (type: 'bifi' | 'btcfi') => {
  const { isTestnet } = useEnv();

  const apiType = useMemo(() => (type === 'bifi' ? 'Bifi' : 'Btcfi'), [type]);

  const { data, isLoading, error, mutate } = useSWR<Handler[]>(
    `/api/getAvailable${apiType}Handlers?isTestnet=${isTestnet}`,
    fetcher,
    SWR_CONFIG
  );

  const sync = useCallback(() => {
    mutate();
  }, [mutate]);

  return {
    handlers: data ?? [],
    isLoading,
    loadError: error,
    sync,
  };
};

export default useAvailableHandlers;
