import axios from 'axios';
import { API_HOST_BRIDGE } from '@/configs/apiHost';
import { AXIOS_CONFIG } from '@/configs/axios';
import ResponseBridgeStatus, {
  ResponseChainStatus,
  ResponseMethod,
  ResponseStatus,
} from '@/types/response/ResponseBridgeStatus';

// Return
export type BridgeProgressStatus = 0 | 1 | 2 | 3 | 4 | 5;
export type BridgeChainStatus = {
  chainId: number;
  tokenAddress: string;
  amount: string;
  address: string;
  transactionHash: string;
  blockHash: string;
  blockNumber: number;
  status: ResponseChainStatus;
  timestamp: number;
};
export type BridgeStatus = {
  progress: BridgeProgressStatus;
  status: ResponseStatus;
  method?: ResponseMethod;
  round?: number;
  sequence?: number;
  src?: BridgeChainStatus;
  dst?: BridgeChainStatus;
};

export const BRIDGE_PROGRESS_LENGTH = 3;

const ENTRYPOINT_BRIDGE_STATUS = {
  HOST: API_HOST_BRIDGE,
  PATH: `/{networkApiPath}/bridge/status`,
  QUERY: '?tx_hash={transactionHash}',
};

const bridgeStatusGetter = async (
  transactionHash: string,
  networkApiPath: string
): Promise<BridgeStatus> => {
  let result: BridgeStatus = {
    progress: 0,
    status: false,
  };

  try {
    const { data } = await axios.get<ResponseBridgeStatus>(
      `${ENTRYPOINT_BRIDGE_STATUS.HOST}${ENTRYPOINT_BRIDGE_STATUS.PATH.replace(
        '{networkApiPath}',
        networkApiPath
      )}${ENTRYPOINT_BRIDGE_STATUS.QUERY.replace(
        '{transactionHash}',
        `${transactionHash}`
      )}`,
      AXIOS_CONFIG
    );

    result = {
      progress: data.status === 'succeed' ? 2 : data.status ? 1 : 0,
      status: data.status,
      method: data.method,
      round: data.round_id,
      sequence: data.sequence,
      src: data.source_chain
        ? {
            chainId: data.source_chain?.chain_id,
            tokenAddress: data.source_chain?.asset,
            amount: data.source_chain?.amount,
            address: data.source_chain?.account,
            transactionHash: data.source_chain?.tx_hash,
            blockHash: data.source_chain?.block_hash,
            blockNumber: data.source_chain?.block_number,
            status: data.source_chain?.status,
            timestamp: data.source_chain?.timestamp,
          }
        : undefined,
      dst: data.destination_chain
        ? {
            chainId: data.destination_chain?.chain_id,
            tokenAddress: data.destination_chain?.asset,
            amount: data.destination_chain?.amount,
            address: data.destination_chain?.account,
            transactionHash: data.destination_chain?.tx_hash,
            blockHash: data.destination_chain?.block_hash,
            blockNumber: data.destination_chain?.block_number,
            status: data.destination_chain?.status,
            timestamp: data.destination_chain?.timestamp,
          }
        : undefined,
    };
  } catch (error) {}

  return result;
};

export default bridgeStatusGetter;
