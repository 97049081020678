import { ChainId } from '@bifrost-platform/bifront-sdk-react-biholder';
import { UnknownNumber } from '@bifrost-platform/bifront-sdk-react-wallet';
import axios from 'axios';
import { ADD_EMPTY, ADD_EMPTY_BY_API } from '@/configs/address';
import { API_HOST_BRIDGE } from '@/configs/apiHost';
import { AXIOS_CONFIG } from '@/configs/axios';
import ActionType from '@/types/action/ActionType';
import ResponseBridgeEncode from '@/types/response/ResponseBridgeEncode';
import { formatNumber } from './formatNumber';

export type EncodedBridgeData = {
  parameter: {
    chain: string;
    token0: string;
    token1: string;
    from: string;
    to: string;
    method: string;
    amount: string;
    variant: string;
  };
  contractAddress: string;
  methodName: string;
};

const ENTRYPOINT_BRIDGE_ENCODE = {
  HOST: API_HOST_BRIDGE,
  PATH: `/{networkApiPath}/bridge/encode_lending`,
  QUERY: `?src_chain_id={chainId}&dst_chain_id={dstChainId}&src_account_addr={address}&src_asset_addr={tokenAddress}&amount={amount}&lending_method={actionType}`,
};

const bridgeEncoder = async ({
  isOutbound,
  chainId,
  address,
  tokenAddress,
  amount,
  actionType,
  chainIdBifrost,
  networkApiPath,
}: {
  isOutbound: boolean;
  chainId: number;
  address: string;
  tokenAddress: string;
  amount: UnknownNumber;
  actionType: ActionType;
  chainIdBifrost: ChainId;
  networkApiPath: string;
}): Promise<EncodedBridgeData> => {
  const { data } = await axios.get<ResponseBridgeEncode>(
    `${ENTRYPOINT_BRIDGE_ENCODE.HOST}${ENTRYPOINT_BRIDGE_ENCODE.PATH.replace(
      '{networkApiPath}',
      networkApiPath
    )}${ENTRYPOINT_BRIDGE_ENCODE.QUERY.replace(
      '{chainId}',
      `${isOutbound ? chainIdBifrost : chainId}`
    )
      .replace('{dstChainId}', `${isOutbound ? chainId : chainIdBifrost}`)
      .replace('{address}', address)
      .replace(
        '{tokenAddress}',
        tokenAddress === ADD_EMPTY ? ADD_EMPTY_BY_API : tokenAddress
      )
      .replace('{amount}', `${formatNumber(amount)}`)
      .replace('{actionType}', actionType)}`,
    AXIOS_CONFIG
  );

  return {
    parameter: data.parameters,
    contractAddress: data.contract_addr,
    methodName: data.method_name,
  };
};

export default bridgeEncoder;
