import { BiholderProvider } from '@bifrost-platform/bifront-sdk-react-biholder';
import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { createContext, PropsWithChildren } from 'react';
import { API_HOST_BIHOLDER } from '@/configs/apiHost';
import { AXIOS_CONFIG } from '@/configs/axios';

export type Props = {};

export const Context = createContext<Props>({});

const BiholderProviderWithWallet = ({ children }: PropsWithChildren) => {
  const { account } = useWallet();

  return (
    <Context.Provider value={{}}>
      <BiholderProvider
        props={{
          address: account,
          axiosBiholderProps: {
            createAxiosDefaults: {
              baseURL: API_HOST_BIHOLDER,
              ...AXIOS_CONFIG,
            },
          },
        }}
      >
        {children}
      </BiholderProvider>
    </Context.Provider>
  );
};

export default BiholderProviderWithWallet;
