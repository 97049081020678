import { useCallback } from 'react';
import useSWR from 'swr';
import { SWR_CONFIG } from '@/configs/swr';
import ChainWithInfo from '@/types/ChainWithInfo';
import useEnv from '../useEnv';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useAvailableChains = () => {
  const { isTestnet } = useEnv();

  const { data, isLoading, error, mutate } = useSWR<ChainWithInfo[]>(
    `/api/getAvailableChains?isTestnet=${isTestnet}`,
    fetcher,
    SWR_CONFIG
  );

  const sync = useCallback(() => {
    mutate();
  }, [mutate]);

  return {
    chains: data ?? [],
    isLoading,
    loadError: error,
    sync,
  };
};

export default useAvailableChains;
