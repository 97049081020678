import { TERM_MINUTE } from './term';

export const AXIOS_HEADERS_CONFIG = {
  'X-Service-ID': 'btcfi-v1',
};

export const AXIOS_CONFIG = {
  withCredentials: false,
  timeout: TERM_MINUTE * 2,
  headers: AXIOS_HEADERS_CONFIG,
};
